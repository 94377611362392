import { JobConditionAssessmentWarrantyTypeOption } from '@models/jobConditionAssessment'
import { WorkPlaceDetailed } from '@models/workPlace'
import { formatServerDate } from '@utils/date'
import { FormValues } from './index'

const getDefaultValues = (
  workPlace: WorkPlaceDetailed | undefined,
  jobConditionAssessmentWarranties: JobConditionAssessmentWarrantyTypeOption[]
): Partial<FormValues> => {
  const result: Partial<FormValues> = {
    jobConditionAssessmentWarranties: jobConditionAssessmentWarranties.map(
      ({ code, title }) => ({
        title,
        code,
        actual: false,
        required: false,
        reason: '',
      })
    ),
  }

  if (!workPlace?.jobConditionAssessment) return result

  return {
    ...result,
    ...workPlace,
    ...workPlace.jobConditionAssessment,
    date: workPlace.jobConditionAssessment.date
      ? formatServerDate(workPlace.jobConditionAssessment.date)
      : undefined,
    file: undefined,
    company: workPlace.company
      ? {
          label: workPlace.company.title,
          value: workPlace.company['@id'],
        }
      : undefined,
    filial: workPlace.companyBranch
      ? {
          label: workPlace.companyBranch.title,
          value: workPlace.companyBranch['@id'],
        }
      : undefined,
    division: workPlace.subdivision
      ? {
          label: workPlace.subdivision.title,
          value: workPlace.subdivision['@id'],
        }
      : undefined,
    position: workPlace.jobPosition
      ? {
          label: workPlace.jobPosition.title,
          value: workPlace.jobPosition['@id'],
        }
      : undefined,
    countOnWorkplace: String(workPlace.countOnWorkplace),
    countOnSimilarWorkplaces: String(workPlace.countOnSimilarWorkplaces),
    womenCount: String(workPlace.womenCount),
    under18Count: String(workPlace.under18Count),
    disabledCount: String(workPlace.disabledCount),
    chemicalClass: workPlace.jobConditionAssessment.chemicalClass
      ? {
          label: workPlace.jobConditionAssessment.chemicalClass,
          value: workPlace.jobConditionAssessment.chemicalClass,
        }
      : undefined,
    biologyClass: workPlace.jobConditionAssessment.biologyClass
      ? {
          label: workPlace.jobConditionAssessment.biologyClass,
          value: workPlace.jobConditionAssessment.biologyClass,
        }
      : undefined,
    workingConditionFinalClass: workPlace.jobConditionAssessment
      .workingConditionFinalClass
      ? {
          label: workPlace.jobConditionAssessment.workingConditionFinalClass,
          value: workPlace.jobConditionAssessment.workingConditionFinalClass,
        }
      : undefined,
    microclimateParametersFactor: workPlace.jobConditionAssessment
      .microclimateParametersFactor
      ? {
          label: workPlace.jobConditionAssessment.microclimateParametersFactor,
          value: workPlace.jobConditionAssessment.microclimateParametersFactor,
        }
      : undefined,
    aerosolsFactor: workPlace.jobConditionAssessment.aerosolsFactor
      ? {
          label: workPlace.jobConditionAssessment.aerosolsFactor,
          value: workPlace.jobConditionAssessment.aerosolsFactor,
        }
      : undefined,
    infrasoundFactor: workPlace.jobConditionAssessment.infrasoundFactor
      ? {
          label: workPlace.jobConditionAssessment.infrasoundFactor,
          value: workPlace.jobConditionAssessment.infrasoundFactor,
        }
      : undefined,
    noiseFactor: workPlace.jobConditionAssessment.noiseFactor
      ? {
          label: workPlace.jobConditionAssessment.noiseFactor,
          value: workPlace.jobConditionAssessment.noiseFactor,
        }
      : undefined,
    ultrasoundAirFactor: workPlace.jobConditionAssessment.ultrasoundAirFactor
      ? {
          label: workPlace.jobConditionAssessment.ultrasoundAirFactor,
          value: workPlace.jobConditionAssessment.ultrasoundAirFactor,
        }
      : undefined,
    generalVibrationFactor: workPlace.jobConditionAssessment
      .generalVibrationFactor
      ? {
          label: workPlace.jobConditionAssessment.generalVibrationFactor,
          value: workPlace.jobConditionAssessment.generalVibrationFactor,
        }
      : undefined,
    ionizingRadiationFactor: workPlace.jobConditionAssessment
      .ionizingRadiationFactor
      ? {
          label: workPlace.jobConditionAssessment.ionizingRadiationFactor,
          value: workPlace.jobConditionAssessment.ionizingRadiationFactor,
        }
      : undefined,
    nonIonizingRadiationFactor: workPlace.jobConditionAssessment
      .nonIonizingRadiationFactor
      ? {
          label: workPlace.jobConditionAssessment.nonIonizingRadiationFactor,
          value: workPlace.jobConditionAssessment.nonIonizingRadiationFactor,
        }
      : undefined,
    lightEnvironmentParametersFactor: workPlace.jobConditionAssessment
      .lightEnvironmentParametersFactor
      ? {
          label:
            workPlace.jobConditionAssessment.lightEnvironmentParametersFactor,
          value:
            workPlace.jobConditionAssessment.lightEnvironmentParametersFactor,
        }
      : undefined,
    laborProcessSeverityFactor: workPlace.jobConditionAssessment
      .laborProcessSeverityFactor
      ? {
          label: workPlace.jobConditionAssessment.laborProcessSeverityFactor,
          value: workPlace.jobConditionAssessment.laborProcessSeverityFactor,
        }
      : undefined,
    laborProcessTensionFactor: workPlace.jobConditionAssessment
      .laborProcessTensionFactor
      ? {
          label: workPlace.jobConditionAssessment.laborProcessTensionFactor,
          value: workPlace.jobConditionAssessment.laborProcessTensionFactor,
        }
      : undefined,
    localVibrationFactor: workPlace.jobConditionAssessment.localVibrationFactor
      ? {
          label: workPlace.jobConditionAssessment.localVibrationFactor,
          value: workPlace.jobConditionAssessment.localVibrationFactor,
        }
      : undefined,
    jobConditionAssessmentWarranties: jobConditionAssessmentWarranties.map(
      ({ code, title }) => ({
        title,
        code,
        actual: false,
        required: false,
        reason: '',
      })
    ),
  }
}

export default getDefaultValues
