import * as React from 'react'
import { FC, MouseEventHandler } from 'react'
import { useToggle } from 'react-use'
import EditButton from '@organisms/EditButton'
import Modal from '@organisms/Modal'
import { Wrapper } from './styled'

type Props = {
  ModalContent: FC<any> // correct
  modalProps: any // correct
  editText?: string
  refetch?: () => Promise<void> | void
  CustomEditComponent?: FC<{
    onClick: MouseEventHandler
    disabled?: boolean
    isLoading?: boolean
  }>
  onOpen?: () => void | Promise<void>
  isDisabled?: boolean
  isLoading?: boolean
  withoutCloseOnOutsideClick?: boolean
}

const EditModal: FC<Props> = ({
  ModalContent,
  modalProps = {},
  editText,
  refetch,
  CustomEditComponent,
  onOpen,
  isDisabled = false,
  isLoading = false,
  withoutCloseOnOutsideClick,
}) => {
  const [visible, toggleVisible] = useToggle(false)

  const handleClick: MouseEventHandler = async (e) => {
    e.stopPropagation()
    await onOpen?.()
    toggleVisible(true)
  }

  const handleClose = async () => {
    await refetch?.()
    toggleVisible(false)
  }

  return (
    <>
      <Wrapper>
        {CustomEditComponent ? (
          <CustomEditComponent
            onClick={handleClick}
            disabled={isDisabled}
            isLoading={isLoading}
          />
        ) : (
          <EditButton
            onClick={handleClick}
            disabled={isDisabled}
            isLoading={isLoading}
          >
            {editText}
          </EditButton>
        )}
      </Wrapper>
      <Modal
        visible={visible}
        onModalClose={handleClose}
        withoutCloseOnOutsideClick={withoutCloseOnOutsideClick}
      >
        <ModalContent {...modalProps} isEdit handleCloseModal={handleClose} />
      </Modal>
    </>
  )
}

export default EditModal
