import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { Field } from '@templates/AddNewItemModal/Content'
import { selectField } from '@templates/AddNewItemModal/templates'
import useFormProcessor from '@hooks/useFormProcessor'
import Checkbox from '@organisms/Checkbox'
import { useAppDispatch } from '@hooks/useAppDispatch'
import {
  cleanSavedSelectedForms,
  setSelectedDangers,
} from '@services/riskMapModal'
import { Option } from '@organisms/Select'
import useCommonRiskMapFields from '@modals/RiskMapModal/Dangers/useCommonRiskMapFields'
import Table, { Column } from '@organisms/Table'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import useRiskMapPositionsField from '@hooks/useRiskMapPositionsField'
import useRiskMapsFilteredDangers from '@hooks/useRiskMapsFilteredDangers'
import { RiskMapFilteredDanger } from '@models/riskMap'
import { TopControllersContext } from '@modals/RiskMapModal/Dangers'
import { useAppSelector } from '@hooks/useAppSelector'
import { CheckboxWrapper } from './styled'

const SELECT_BY = 'selectBy'

type TableItem = {
  checkbox: ReactNode
  workPlaces: ReactNode
  object: ReactNode
  kind: ReactNode
  event: ReactNode
  hazard: ReactNode
  key: string
}

const COLUMNS: Column[] = [
  {
    title: '',
    dataIndex: 'checkbox',
  },
  {
    title: 'Работы / Место / Авария',
    dataIndex: 'workPlaces',
  },
  {
    title: 'Объект',
    dataIndex: 'object',
  },
  {
    title: 'Вид',
    dataIndex: 'kind',
  },
  {
    title: 'Опасность',
    dataIndex: 'hazard',
  },
  {
    title: 'Опасное событие',
    dataIndex: 'event',
  },
]

enum SelectBy {
  POSITION = 'POSITION',
  PROPERTIES = 'PROPERTIES',
}

const SELECT_BY_OPTIONS = [
  {
    label: 'Должности',
    value: SelectBy.POSITION,
  },
  {
    label: 'Свойствам опасности',
    value: SelectBy.PROPERTIES,
  },
]

const selectByField = selectField({
  withoutFetchOptions: true,
  options: [
    {
      label: 'Должности',
      value: SelectBy.POSITION,
    },
    {
      label: 'Свойствам опасности',
      value: SelectBy.PROPERTIES,
    },
  ],
  label: 'Выбор по',
  name: SELECT_BY,
})

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  riskMap?: any
}

const SelectDangersModal: FC<Props> = ({
  isEdit = false,
  handleCloseModal,
}) => {
  const { enabledEtn } = useContext(TopControllersContext)
  const prevPositionRef = useRef('')
  const dispatch = useAppDispatch()

  const [checkedDangers, setCheckedDangers] = useState(
    [] as RiskMapFilteredDanger[]
  )

  const checkSelectedDanger = (payload: RiskMapFilteredDanger) => {
    setCheckedDangers((prev) => [...prev, payload])
  }

  const uncheckSelectedDanger = (payload: RiskMapFilteredDanger) => {
    setCheckedDangers((prev) =>
      prev.filter((item) => item['@id'] !== payload['@id'])
    )
  }

  // =====================================

  const methods = useFormProcessor({
    defaultValues: {
      [SELECT_BY]: SELECT_BY_OPTIONS[0],
    },
  })
  const { watch, setValue } = methods
  const selectBy: Option<SelectBy> | undefined = watch(SELECT_BY)

  const workPlace: string | undefined = watch('workPlace')?.value
  const kind: string | undefined = watch('kind')?.value
  const object: string | undefined = watch('object')?.value

  const positionSrc: Array<Option<string>> = watch('position')
  const position: string[] = positionSrc?.map(({ value }) => value)

  const needShowTable = !!position || !!workPlace || !!kind || !!object

  useEffect(() => {
    setValue('position', undefined)
    setValue('workPlace', undefined)
    setValue('kind', undefined)
    setValue('object', undefined)
  }, [selectBy])

  // =====================================

  const handleSetDanger = (checked: boolean, value: RiskMapFilteredDanger) => {
    if (checked) {
      checkSelectedDanger(value)
    } else {
      uncheckSelectedDanger(value)
    }
  }

  const handleSubmitForm = () => {
    dispatch(setSelectedDangers(checkedDangers))
    dispatch(cleanSavedSelectedForms())
    handleCloseModal()
  }

  // =====================================

  const {
    data: dangersData,
    setFilters: dangersSetFilters,
    setPage,
    isLoading,
  } = useRiskMapsFilteredDangers({
    skip: !needShowTable,
  })

  const { dangerWorkPlacesField, dangersObjects, dangerKindsField } =
    useCommonRiskMapFields(methods, {
      dangersSetFilters,
      isRequiredForm: false,
      inSelectDangersModal: true,
      ignoreJobPositions: true,
    })

  useEffect(() => {
    const positionString = JSON.stringify(position)
    if (
      position?.length > 0 &&
      prevPositionRef.current !== positionString &&
      !workPlace &&
      !kind &&
      !object
    ) {
      dangersSetFilters({
        jobPosition: position,
        etn: enabledEtn ? 'true' : undefined,
      })

      prevPositionRef.current = positionString
    }
  }, [position])

  const selectedMainPosition = useAppSelector(
    (state) => state.riskMapModal.selectedMainPosition
  )
  const { field: positionsField } = useRiskMapPositionsField({
    isMulti: true,
    withManualEntryText: false,
    prepareSearch: (value) =>
      value !== '' ? value : selectedMainPosition?.title || '',
  })

  const { items: dangersItems = [], params } = dangersData || {}

  const firstLine: Field[] = [selectByField]
  let secondLine: Field[] | null = null
  if (selectBy?.value === SelectBy.PROPERTIES) {
    secondLine = [dangerWorkPlacesField(), dangersObjects(), dangerKindsField()]
  } else if (selectBy?.value === SelectBy.POSITION) {
    secondLine = [positionsField()]
  }

  const tableData: TableItem[] = []

  const needCalculateTable =
    needShowTable &&
    params &&
    ((params.jobPosition &&
      position &&
      isEqual(position, params.jobPosition)) ||
      (params.workPlace && workPlace && workPlace === params.workPlace) ||
      (params.object && object && object === params.object) ||
      (params.kind && kind && kind === params.kind))

  if (needCalculateTable) {
    for (const item of dangersItems) {
      const { '@id': id, workPlace, object, kind, hazard, event } = item
      tableData.push({
        checkbox: (
          <Checkbox
            onChange={(newValue) => handleSetDanger(!!newValue, item)}
          />
        ),
        workPlaces: workPlace?.value,
        object: object?.value,
        kind: kind?.value,
        event: event?.value,
        hazard: hazard?.value,
        key: id,
      })
    }
  }

  return (
    <AddNewItemModalLayout
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      isEdit={isEdit}
      saveText="Добавить выбранные опасности"
      customFullTitle="Отфильтровать и выбрать опасности"
      withStopPropagation
    >
      <ExternalLineWrapper>
        <LineFields fields={firstLine} />
        {secondLine && <LineFields fields={secondLine} />}
      </ExternalLineWrapper>
      {needShowTable && (
        <ExternalLineWrapper>
          <CheckboxWrapper>
            <Table
              isLoading={isLoading}
              columns={COLUMNS}
              data={tableData}
              pagination={{
                position: ['bottomCenter'],
                onChange: setPage,
                total: tableData?.length,
                showSizeChanger: false,
                pageSize: 20,
              }}
            />
          </CheckboxWrapper>
        </ExternalLineWrapper>
      )}
    </AddNewItemModalLayout>
  )
}

export default SelectDangersModal
