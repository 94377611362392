import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import AddNewItemModal from '@templates/AddNewItemModal'
import FileErrorComponent from '@templates/FileErrorComponent'
import { Option } from '@organisms/Select'
import { useCreateRiskMapFromFileMutation } from '@services/riskMaps'
import { File } from '@models/file'
import useMe from '@hooks/useMe'
import useRiskMapFromFileFields, {
  AUTO_NAME,
  AUTO_SIZ_NAME,
} from './useRiskMapFromFileFields'

type FormValues = {
  company: Option
  file: File
  filial: Option
}

type Props = {
  handleCloseModal: () => void
}

const RiskMapModalFromFile: FC<Props> = ({ handleCloseModal }) => {
  const { company, companyBranch } = useMe()
  const [createRiskMapFromFile, postData] = useCreateRiskMapFromFileMutation()

  const methods = useFormProcessor()
  const fields = useRiskMapFromFileFields(methods)

  const createDangers: boolean = methods.watch(AUTO_NAME)
  const autoAddProtectionMeans: boolean = methods.watch(AUTO_SIZ_NAME)

  const handleSubmit = (form: FormValues) => {
    void createRiskMapFromFile({
      createDangers,
      autoAddProtectionMeans: createDangers && autoAddProtectionMeans,
      file: form.file?.['@id'],
      company: form.company?.value || company['@id'],
      companyBranch: form.filial?.value || companyBranch['@id'],
    })
  }

  return (
    <AddNewItemModal
      {...postData}
      customFullTitle="Загрузка карты рисков из .xlsx"
      contentBeforeFields={
        <DownloadExampleButton
          templateLink="./import_risk_maps_template.xlsx"
          title="Школа экспертов — Шаблон импорта карт рисков.xlsx"
          description="Загрузите файл .xlsx, составленный по образцу. После загрузки будут созданы подразделения, должности и карты рисков из файла."
        />
      }
      methods={methods}
      onSubmitForm={handleSubmit}
      fields={fields}
      handleCloseModal={handleCloseModal}
      CustomErrorComponent={FileErrorComponent}
      successContentAddTitle="Загружаем карты рисков"
      successContentDescription={
        <div>
          <div>После обработки файла вам придёт уведомление на платформу.</div>
          <div>Если в файле будут ошибки, вы получите их список.</div>
          <div>Ни одна карта, при этом, не будет добавлена.</div>
          <div>После исправления ошибок вам нужно повторно загрузить файл.</div>
        </div>
      }
    />
  )
}

export default RiskMapModalFromFile
