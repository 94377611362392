import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form'
import { get } from 'lodash'
import { Description, Wrapper } from '@templates/Field/styled'
import Text from '@organisms/Text'
import Prompt from '@organisms/Prompt'
import FormField from './FormField'

const MAX_DESCRIPTION_LENGTH = 88

// correct
type Props<ComponentProps = any> = {
  defaultValue?: unknown
  name: string
  nameForErrors?: string | null
  label: string
  rules?: Omit<
    RegisterOptions<FieldValues, 'name'>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  Component: FC<ComponentProps>
  CustomComponent?: FC<ComponentProps>
  componentProps?: ComponentProps
  widthMultiplier?: number
  isSelect?: boolean
  withRightLabel?: boolean
  promptText?: ReactNode
  emptyValue?: any
  id?: string
  descriptionPath?: string
  selectedMultilineValues?: string[]
}

const Field: FC<Props> = ({
  defaultValue,
  name,
  nameForErrors,
  label,
  rules,
  Component,
  componentProps,
  widthMultiplier = 1,
  CustomComponent,
  withRightLabel = false,
  promptText,
  emptyValue = null,
  id,
  descriptionPath,
  selectedMultilineValues = [],
}) => {
  const {
    control,
    clearErrors,
    formState: { errors },
    watch,
  } = useFormContext()
  const val = watch(name)
  const errorForErrorName = nameForErrors
    ? errors?.[nameForErrors]?.message
    : undefined
  const errorForName = get(errors, name)?.message
  const errorMessage = (errorForName || errorForErrorName) as string

  useEffect(() => {
    if (errorMessage) {
      if (nameForErrors) {
        clearErrors(nameForErrors)
      }

      clearErrors(name)
    }
  }, [val])

  if (CustomComponent) {
    return <CustomComponent {...componentProps} />
  }

  return (
    <FormField
      withRightLabel={withRightLabel}
      widthMultiplier={widthMultiplier}
      label={label}
      promptText={promptText}
      field={
        <Controller
          defaultValue={defaultValue}
          control={control}
          name={name as 'name'}
          rules={rules}
          render={({ field }) => {
            const props = {
              ...field,
              ...componentProps,
              onChange: (...args: any) => {
                componentProps?.onChange?.(...args)
                field.onChange(...args)
              },
              hasError: !!errorMessage,
              selectedMultilineValues,
            }
            props.value = props.value || emptyValue

            const description = descriptionPath
              ? (get(props, descriptionPath) as string)
              : null
            const needPrompt =
              description && description?.length > MAX_DESCRIPTION_LENGTH

            return (
              <Wrapper id={id}>
                <Component {...props} />
                {description && (
                  <Description>
                    <Text type="description">
                      {description.slice(0, MAX_DESCRIPTION_LENGTH)}
                      {needPrompt ? '...' : ''}
                    </Text>
                    {window.ENABLE_LOGS && props.value?.value}
                    {needPrompt && (
                      <Prompt promptText={description} width={350} />
                    )}
                  </Description>
                )}
                {errorMessage && <Text type="error">{errorMessage}</Text>}
              </Wrapper>
            )
          }}
        />
      }
    />
  )
}

export type { Props as FieldProps }
export default Field
